<template>
  <div class="waiting-statuses" ref="waitingStatuses">
    <div class="title">待合状況</div>
    <validation-observer
      class="content"
      ref="validationObserver"
      tag="div"
      v-slot="{ invalid }"
    >
      <div class="header-buttons">
        <div
          class="buttons-wrapper"
          :style="{ '--position-right': headerButtonsPositionRight }"
        >
          <base-button-small-white
            v-if="lookOnlyFlg === 0"
            @click="openPopupForConfirmation"
            >デフォルトに戻す</base-button-small-white
          >
        </div>
      </div>
      <waiting-status-input-table
        :dragging="dragging"
        :karteFlg="karteFlg"
        :waitingStatuses="waitingStatuses"
        @add="addWaitingStatus"
        @input="inputValue"
        @ondragend="toggleDragging(false)"
        @ondragstart="toggleDragging(true)"
        @remove="removeWaitingStatus"
        @sort="setSortedWaitingStatuses"
      />
      <div class="footer-buttons">
        <div
          class="buttons-wrapper"
          :style="{ '--position-left': registrationButtonPositionLeft }"
        >
          <base-button-medium-orange
            v-if="lookOnlyFlg === 0"
            class="button"
            :disabled="awaiting || dragging || invalid"
            @click="updateWaitingStatuses"
            >登録</base-button-medium-orange
          >
        </div>
      </div>
    </validation-observer>
    <announce-popup
      v-if="popup.opened"
      :type="popup.type"
      :title="popup.title"
      :buttons="popup.buttons"
      @cancel="popup.cancel"
      @close="popup.close"
      @decision="popup.decide"
      >{{ popup.message }}</announce-popup
    >
    <unsaved-leave-popup />
  </div>
</template>

<script>
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import WaitingStatusInputTable from '@/components/parts/organisms/WaitingStatusInputTable'
import dedent from 'dedent'
import { ValidationObserver } from 'vee-validate'
import { cloneDeep } from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'WaitingStatuses',

  components: {
    AnnouncePopup,
    BaseButtonMediumOrange,
    BaseButtonSmallWhite,
    UnsavedLeavePopup,
    ValidationObserver,
    WaitingStatusInputTable
  },

  mixins: [CheckInputDifference],

  props: {
    settingsContentsScrollLeft: { type: Number, default: 0 },
    settingsContentsWidth: { type: Number, default: 0 }
  },

  data() {
    return {
      addedCount: 0,
      awaiting: false,
      dragging: false,
      linkedContents: {
        1: '',
        2: 'reservation',
        3: 'karte',
        4: 'payment'
      },
      popup: {
        buttons: [''],
        cancel: () => {},
        close: this.closePopup,
        decide: () => {},
        message: '',
        opened: false,
        title: '',
        type: ''
      },
      waitingStatuses: [],
      waitingStatusesObserver: null,
      waitingStatusesWidth: 0
    }
  },

  computed: {
    ...mapGetters({
      karteFlg: 'auth/karteFlg',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    contentsBodyBorderBoxWidth() {
      // class="contents-body" の横幅
      const contentsBodyPadding = 48
      const contentsBodyBorderBoxWidth =
        contentsBodyPadding + this.waitingStatusesWidth + contentsBodyPadding
      return contentsBodyBorderBoxWidth
    },
    headerButtonsPositionRight() {
      // <div class="waiting-statuses"> の右端からボタン右端までの距離
      const existsScrollX =
        this.contentsBodyBorderBoxWidth > this.rightEndXOfDisplayArea
      const positionRight = existsScrollX
        ? this.contentsBodyBorderBoxWidth - this.rightEndXOfDisplayArea
        : 0
      return positionRight + 'px'
    },
    registrationButtonPositionLeft() {
      // <div class="waiting-statuses"> の左端からボタンまでの距離
      const contentsBodyPaddingLeft = 48
      const registrationButtonWidth = 200
      const positionLeft =
        this.settingsContentsWidth / 2 +
        this.settingsContentsScrollLeft -
        contentsBodyPaddingLeft -
        registrationButtonWidth / 2
      return positionLeft + 'px'
    },
    rightEndXOfDisplayArea() {
      // class="contents-body" の左端を起点とした表示領域の右端のX座標
      const rightEndXOfDisplayArea =
        this.settingsContentsScrollLeft + this.settingsContentsWidth
      return rightEndXOfDisplayArea
    }
  },

  created() {
    this.setWaitingStatuses()
    this.$nextTick(() => {
      this.$refs.validationObserver.validate()
    }) // 入力前から検証を行う
  },

  mounted() {
    this.waitingStatusesObserver = new ResizeObserver(() => {
      this.waitingStatusesWidth = this.$refs.waitingStatuses.clientWidth
    })
    this.waitingStatusesObserver.observe(this.$refs.waitingStatuses)
  },

  beforeDestroy() {
    this.waitingStatusesObserver.disconnect()
  },

  methods: {
    closePopup() {
      this.popup.opened = false
    },
    setWaitingStatuses() {
      this.waitingStatuses = cloneDeep(
        this.$store.getters['waitingStatuses/getData']
      )
      this.mixinInputData = this.waitingStatuses
      this.mixinSetInitialData()
    },
    inputValue({ index, key, value }) {
      if (key === 'color' && !value) {
        this.waitingStatuses[index].color = '#'
      } else if (key === 'timeSettableStatusFlg') {
        this.waitingStatuses = this.waitingStatuses.map((v, i) => {
          const newValue = index === i ? 1 : 0
          v.timeSettableStatusFlg = newValue
          return v
        })
      } else if (key === 'initialValueFlg') {
        this.waitingStatuses = this.waitingStatuses.map((v, i) => {
          const newValue = index === i ? 1 : 0
          v.initialValueFlg = newValue
          return v
        })
      } else if (key === 'linkedContent') {
        this.waitingStatuses[index].linkedContent = this.linkedContents[value]
      } else {
        this.waitingStatuses[index][key] = value
      }
    },
    removeWaitingStatus({ index }) {
      this.awaiting = true
      this.waitingStatuses.splice(index, 1)
      this.awaiting = false
    },
    addWaitingStatus() {
      this.awaiting = true
      this.addedCount++
      const waitingStatus = {
        id: -this.addedCount,
        defaultWaitingStatusId: 0,
        name: '',
        color: '#ef6c00',
        linkedContent: 'reservation',
        initialValueFlg: 0,
        timeSettableStatusFlg: 0,
        menuDisplayableStatusFlg: 1,
        reservationChangeableStatusFlg: 1,
        reservationSelectableStatusFlg: 1,
        nextWaitingStatusId: 0,
        medicalContentCreateWaitingStatusId: 0,
        medicalContentDeleteWaitingStatusId: 0,
        medicalPaymentCreateWaitingStatusId: 0,
        medicalPaymentDeleteWaitingStatusId: 0,
        inHospitalMedicalPaymentCreateWaitingStatusId: 0,
        inHospitalMedicalPaymentDeleteWaitingStatusId: 0,
        paymentCreateWaitingStatusId: 0,
        paymentDeleteWaitingStatusId: 0
      }
      this.waitingStatuses.push(waitingStatus)
      this.awaiting = false
    },
    toggleDragging(value) {
      this.dragging = value
    },
    setSortedWaitingStatuses(sortedWaitingStatuses) {
      this.waitingStatuses = sortedWaitingStatuses
      this.mixinInputData = sortedWaitingStatuses
    },
    async updateWaitingStatuses() {
      this.awaiting = true
      // 来院時間設定の確認
      const existsTimeSettableStatusFlg = this.waitingStatuses.some(
        v => v.timeSettableStatusFlg
      )
      if (!existsTimeSettableStatusFlg) {
        const message = '来院時間を設定する待合状況を選択してください。'
        this.openPopupForWarning(message)
        return
      }
      // 待合状況初期選択値の確認
      const existsInitialValueFlg = this.waitingStatuses.some(
        v => v.initialValueFlg
      )
      if (!existsInitialValueFlg) {
        const message = '予約での初期値に使用する待合状況を選択してください。'
        this.openPopupForWarning(message)
        return
      }
      // 現在の並び順で order を設定
      const waitingStatusesAfterInput = this.waitingStatuses.map((v, i) => {
        const order = i + 1
        return { ...v, order }
      })
      const result = await this.$store.dispatch('waitingStatuses/update', {
        waitingStatusesBeforeInput: this.mixinInitialData,
        waitingStatusesAfterInput
      })
      if (result === 'success') {
        this.popup.type = 'success'
        this.popup.title = '完了'
        this.popup.message = '編集しました'
        this.popup.close = this.closePopup
        this.setWaitingStatuses()
      } else {
        this.popup.type = 'failure'
        this.popup.title = '失敗'
        if (result === 'already updated') {
          this.popup.message = dedent`
            編集に失敗しました。
            他のユーザーにより待合状況が変更されています。
            最新の待合状況に更新します。
            編集を行う場合は再度入力をお願いします。`
          this.popup.close = () => {
            this.setWaitingStatuses()
            this.closePopup()
            this.$nextTick(() => {
              this.popup.close = this.closePopup
            })
          }
        } else {
          this.popup.message = '編集に失敗しました'
          this.popup.close = this.closePopup
        }
      }
      this.popup.buttons = ['閉じる']
      this.popup.opened = true
      this.awaiting = false
    },
    openPopupForWarning(message) {
      this.popup.type = 'alert'
      this.popup.title = '注意'
      this.popup.message = message
      this.popup.buttons = ['閉じる']
      this.popup.close = () => {
        this.awaiting = false
        this.closePopup()
        this.$nextTick(() => {
          this.popup.close = this.closePopup
        })
      }
      this.popup.opened = true
    },
    openPopupForConfirmation() {
      this.popup.type = 'alert'
      this.popup.title = '注意'
      this.popup.message = dedent`
        初期から存在している待合状況をデフォルト
        の設定に戻してもよろしいですか？`
      this.popup.buttons = ['戻さない', '戻す']
      this.popup.cancel = this.closePopup
      this.popup.decide = this.restoreWaitingStatuses
      this.popup.opened = true
    },
    async restoreWaitingStatuses() {
      this.awaiting = true
      const result = await this.$store.dispatch('waitingStatuses/restore')
      if (result === 'success') {
        this.popup.type = 'success'
        this.popup.title = '完了'
        this.popup.message = '編集しました'
        this.setWaitingStatuses()
      } else {
        this.popup.type = 'failure'
        this.popup.title = '失敗'
        this.popup.message = '編集に失敗しました'
      }
      this.popup.buttons = ['閉じる']
      this.popup.opened = true
      this.awaiting = false
    }
  }
}
</script>

<style lang="scss" scoped>
.waiting-statuses {
  width: 100%;
  min-width: 680px;
  text-align: left;
  > .title {
    padding-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px solid #{$light-grey};
  }
  > .content {
    width: 100%;
    > .header-buttons {
      height: 20px + 33px + 20px;
      position: relative;
      display: flex;
      align-items: center;
      > .buttons-wrapper {
        position: absolute;
        right: var(--position-right);
      }
    }
    > .footer-buttons {
      margin-top: 60px;
      height: 200px;
      position: relative;
      > .buttons-wrapper {
        position: absolute;
        left: var(--position-left);
      }
    }
  }
}
</style>
